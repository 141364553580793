const logger = {
    log   : console.log,
    info  : console.info,
    warn  : console.warn,
    error : console.error,
    debug : console.debug
};

for (const loglevel of Object.getOwnPropertyNames(logger)) {
    console[loglevel] = (...data) => {
        const logdata = {
            level : loglevel,
            data
        };

        fetch('https://az.modelate.de/api/send-log', {
            method : 'POST',
            body   : JSON.stringify(logdata)
        });

        logger[loglevel](...data);
    };
}

if (navigator.userAgent.includes('Trident')) console.log('Not IE');
else console.log('Runs in IE');
